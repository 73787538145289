import axios from 'axios'
import create from 'zustand'

export const useReportsStore = create(set => ({
   reports: [],
   getReports: async() => {
      const response = await axios.get('http://localhost:4000/report')
      const data = response.data.map(item => {
         return {
            name: item.name,
            date: item.createdAt,
            status: item.status,
            id: item.id
         }
      })
      return set(() => ({ reports: data }))
   }
}))
