/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Grid, GridItem } from "@chakra-ui/react";

// Custom components
import General from "views/admin/dashboard/components/General";
import Notifications from "views/admin/dashboard/components/Notifications";
import Projects from "views/admin/dashboard/components/Projects";
import Upload from "views/admin/dashboard/components/Upload";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import React from "react";
import Welcome from "./components/Welcome";
import ComplexTable from "views/admin/default/components/ComplexTable";

import { columnsDataComplex } from "views/admin/default/variables/columnsData";

import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import TotalSpent from "../default/components/TotalSpent";

export default function Overview() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid>
        <Welcome />
      </Grid>
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
        }}
        templateRows={{
          base: "1fr",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <TotalSpent />
        <Upload
          gridArea={{
            base: "1 / 2",
            lg: "1 / 2",
          }}
          minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
          pe='20px'
          pb={{ base: "100px", lg: "20px" }}
        />
      </Grid>
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(3, 1fr)",
          "2xl": "repeat(3, 1fr)",
        }}
        templateRows={{
          base: "1fr",
          lg: "1fr",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
          <GridItem colStart={1} colEnd={3}>
            <ComplexTable columnsData={columnsDataComplex} tableData={tableDataComplex} />
          </GridItem>
          <GridItem>
            <Projects
              gridArea='1 / 2 / 2 / 2'
              banner={banner}
              avatar={avatar}
              name='Adela Parkson'
              job='Product Designer'
              posts='17'
              followers='9.7k'
              following='274'
            />
          </GridItem>
      </Grid>
    </Box>
  );
}
