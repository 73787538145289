import React, { useEffect, useState } from 'react'
import { useReportsStore } from 'store/reports.store';
import ComplexTable from "views/admin/default/components/ComplexTable";
import { columnsDataComplex } from "views/admin/default/variables/columnsData";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";

const Reports = () => {
   const [listReports, setListReports] = useState([])

  const reportStore = useReportsStore(state => state)

  useEffect(() => {
    reportStore.getReports()
  }, [])

  useEffect(() => {
    if (reportStore.reports.length) {
      setListReports(reportStore.reports)
    }
  }, [reportStore.reports])
   return (
      <div style={{ marginTop: 80 }}>
         <ComplexTable columnsData={columnsDataComplex} tableData={listReports} />
      </div>
   )
}

export default Reports
