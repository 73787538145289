import axios from 'axios'
import create from 'zustand'

export const useLoginStore = create(set => ({
   login: {
      isLogged: false,
      token: ''
   },
   setLogin: async (data) => {
      console.log('data', data);
      if (data.email && data.password) {
         const response = await axios.post('http://localhost:4000/auth/login', data)
         console.log('response.data', response.data)
         return set((state) => ({ login: { ...state.login,...response.data, isLogged: true } }))
      }
   }
}))
