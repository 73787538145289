// Chakra imports
import { Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useEffect } from "react";
import { useLoginStore } from "store/login.store";

export default function Welcome(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  
  const loginStore = useLoginStore(state => state)

  return (
    <Card mb={{ base: "20px", "2xl": "20px" }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Bem vindo, { loginStore.login.name }
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
        dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet,
        consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
      </Text>
    </Card>
  );
}
