import { Flex, Grid, GridItem } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react'
import { lineChartOptionsTotalSpent } from 'variables/charts';
import ComplexTable from "./components/ComplexTable";
import { columnsDataComplex } from "views/admin/default/variables/columnsData";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import Equalizer from './components/Equalizer';
import PersonalGraph from './components/PersonalGraph';


const Reports = () => {
      // Otimista
      const graphDataCool = [
         {
            "name": "Spend",
            "data": [
               2999.94,
               2998.42,
               3995.99,
               3997.48,
               3998.74,
               4997.39,
               4999.15,
               4998.96,
               6000.00,
               5998.93,
               5999.32,
               6998.89,
               6999.09,
               6998.94,
            ]
         },
         {
            "name": "Conversões",
            "data": [
               967,
               983,
               1157,
               1099,
               1075,
               1234,
               1233,
               1226,
               1355,
               1292,
               1289,
               1390,
               1413,
               1412
            ]
         },
      ]
      // Bom
      const graphDataGood = [
         {
            "name": "Custo Campanha",
            "data": [
               2999.94,
               2998.42,
               3995.99,
               3997.48,
               3998.74,
               4997.39,
               4999.15,
               4998.96,
               6000.00,
               5998.93,
               5999.32,
               6998.89,
               6999.09,
               6998.94,
            ]
         },
         {
            "name": "Conversões",
            "data": [
               867,
               883,
               1057,
               999,
               975,
               1134,
               1133,
               1126,
               1255,
               1192,
               1189,
               1290,
               1313,
               1312
            ]
         },
      ]
   
      // Ruim
      const graphDataBad = [
         {
            "name": "Custo Campanha",
            "data": [
               2999.94,
               2998.42,
               3995.99,
               3997.48,
               3998.74,
               4997.39,
               4999.15,
               4998.96,
               6000.00,
               5998.93,
               5999.32,
               6998.89,
               6999.09,
               6998.94,
            ]
         },
         {
            "name": "Conversões",
            "data": [
               802,
               872,
               875,
               894,
               905,
               934,
               996,
               1043,
               1145,
               1012,
               1029,
               1140,
               1243,
               1252
            ]
         },
      ]

   const [graphData, setGraphData] = useState(graphDataCool);
   const [estimative, setEstimative] = useState(3);

   const convertMoneyValue = (number) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number)

   const graphOptions = {
      chart: {
         type: 'line',
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: false,
          top: 13,
          left: 0,
          blur: 10,
          opacity: 0.1,
          color: "#4318FF",
        },
      },
      forecastDataPoints: {
         count: 7
       },
      colors: ["#4318FF", "#39B8FF"],
      markers: {
        size: 0,
        colors: "white",
        strokeColors: "#7551FF",
        strokeWidth: 3,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        showNullDataPoints: true,
        shape: "circle",
      },
      tooltip: {
        theme: "dark",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        type: "line",
      },
      xaxis: {
        type: "datetime",
        categories: ['08/04/2022', '08/05/2022','08/06/2022','08/07/2022','08/08/2022','08/09/2022','08/10/2022','08/11/2022','08/12/2022','08/13/2022','08/14/2022','08/15/2022','08/16/2022','08/17/2022','08/18/2022'],
        labels: {
         formatter: function(value) {
            return dayjs(value).format('DD MMM')
          },
          style: {
            colors: "#A3AED0",
            fontSize: "12px",
            fontWeight: "500",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      fill: {
         type: "gradient",
         gradient: {
            // shade: "dark",
            // type: "horizontal",
            shadeIntensity: 3,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            // inverseColors: true,
            opacityFrom: 0.2,
            opacityTo: 0.8,
            stops: [],
         },
      },
      yaxis: [
         {
            show: true,
            labels: {
               formatter: function(value) {
                  return convertMoneyValue(value)
                },
                style: {
                  colors: "#A3AED0",
                  fontSize: "12px",
                  fontWeight: "500",
                },
              },
          },
          {
            show: true,
            opposite: true,
            labels: {
               formatter: function(value) {
                  return value.toFixed(0)
                },
                style: {
                  colors: "#A3AED0",
                  fontSize: "12px",
                  fontWeight: "500",
                },
              },
          }
      ],
      legend: {
        show: true,
      },
      grid: {
        show: false,
        column: {
          color: ["#7551FF", "#39B8FF"],
          opacity: 0.5,
        },
      },
      color: ["#7551FF", "#39B8FF"],
    };

   useEffect(() => {

      if (estimative > 66)  {
         if (graphData !== graphDataCool) {
            return setGraphData(graphDataCool);
         }
      }
      if (estimative > 33 && estimative <= 66)  {
         if (graphData !== graphDataGood) {
            return setGraphData(graphDataGood);
         }
      }
      if (estimative <= 33)  {
         if (graphData !== graphDataBad) {
            return setGraphData(graphDataBad);
         }
      }
   }, [estimative])

   const columnsDataComplex = [
      {"Header": "Nome da Campanha",
"accessor": "campaign"
},
{"Header": "Última data da campanha",
"accessor": "last_date"
},
{"Header": "Dias a serem projetados",
"accessor": "projection_days"
},
{"Header": "Data máxima projetada",
"accessor": "projected_max_date"
},
{"Header": "custo total atual da campanha",
"accessor": "total_cost"
},
{"Header": "receita total atual da campanha",
"accessor": "total_revenue"
},
{"Header": "Lucro Total Atual da campanha",
"accessor": "total_profit"
},
{"Header": "Custo Diário Atual da campanha",
"accessor": "cost_per_day"
},
{"Header": "Custo diário Sugerido",
"accessor": "suggested_cost_per_day"
},
{"Header": "Custo Projeto",
"accessor": "projected_cost"
},
{"Header": "Receita Projetada",
"accessor": "projected_revenue"},
{  "Header": "Lucro Projetado",
   "accessor": "projected_profit"
},
{"Header": "Roi Projetado",
"accessor": "projected_roi"
},
{"Header": "Tipo de Otimização",
"accessor": "optimization_tag"
},
    ];
   
    const returnResponse = {
      "schema": {
        "fields": [
          {
            "name": "index",
            "type": "integer"
          },
          {
            "name": "campaign",
            "type": "string"
          },
          {
            "name": "last_date",
            "type": "datetime"
          },
          {
            "name": "projection_days",
            "type": "integer"
          },
          {
            "name": "projected_max_date",
            "type": "datetime"
          },
          {
            "name": "total_cost",
            "type": "number"
          },
          {
            "name": "total_revenue",
            "type": "number"
          },
          {
            "name": "total_profit",
            "type": "number"
          },
          {
            "name": "cost_per_day",
            "type": "number"
          },
          {
            "name": "suggested_cost_per_day",
            "type": "number"
          },
          {
            "name": "projected_cost",
            "type": "number"
          },
          {
            "name": "projected_revenue",
            "type": "number"
          },
          {
            "name": "projected_profit",
            "type": "number"
          },
          {
            "name": "projected_roi",
            "type": "number"
          },
          {
            "name": "optimization_tag",
            "type": "string"
          },
          {
            "name": "cost_coef",
            "type": "number"
          },
          {
            "name": "days_coef",
            "type": "number"
          },
          {
            "name": "intercept",
            "type": "number"
          }
        ],
        "primaryKey": [
          "index"
        ],
        "pandas_version": "1.4.0"
      },
      "data": [
        {
          "index": 5,
          "campaign": "LINHA LARANJA | LOJA SEARA | DISCOVERY TOP PRODUTO | REGIÕES | GENÉRICA / 12520883199",
          "last_date": "2021-09-01T00:00:00.000Z",
          "projection_days": 60,
          "projected_max_date": "2021-10-31T00:00:00.000Z",
          "total_cost": 75197.45,
          "total_revenue": 297679.51,
          "total_profit": 222482.06,
          "cost_per_day": 1044.4090277778,
          "suggested_cost_per_day": 2512.5417121008,
          "projected_cost": 150752.5027260459,
          "projected_revenue": 632115.9509978791,
          "projected_profit": 481363.4482718332,
          "projected_roi": 3.1930710241,
          "optimization_tag": "under_performance",
          "cost_coef": 4.2485550721,
          "days_coef": -8.6602761014,
          "intercept": -7221.2026485566
        },
        {
          "index": 4,
          "campaign": "LINHA LARANJA | LOJA SEARA | DISCOVERY ALL PRODUTO | SP - RJ | GENÉRICA / 12522025890",
          "last_date": "2021-09-09T00:00:00.000Z",
          "projection_days": 60,
          "projected_max_date": "2021-11-08T00:00:00.000Z",
          "total_cost": 239168.0199999999,
          "total_revenue": 624123.6199999998,
          "total_profit": 384955.5999999999,
          "cost_per_day": 2465.6496907216,
          "suggested_cost_per_day": 5005.9259645622,
          "projected_cost": 300355.557873731,
          "projected_revenue": 857276.4056558802,
          "projected_profit": 556920.8477821492,
          "projected_roi": 1.854205235,
          "optimization_tag": "under_performance",
          "cost_coef": 3.032272788,
          "days_coef": -163.9200574962,
          "intercept": -27748.1301905997
        },
        {
          "index": 28,
          "campaign": "RAC_GOOGLE_PESCADOS_LW_CONVERSAO_DISCOVERY_17032022 / 16590153343",
          "last_date": "2022-04-28T00:00:00.000Z",
          "projection_days": 60,
          "projected_max_date": "2022-06-27T00:00:00.000Z",
          "total_cost": 65007.78,
          "total_revenue": 141684.15,
          "total_profit": 76676.37,
          "cost_per_day": 1857.3651428571,
          "suggested_cost_per_day": 2363.3433997658,
          "projected_cost": 141800.6039859468,
          "projected_revenue": 320273.3475145368,
          "projected_profit": 178472.74352859,
          "projected_roi": 1.258617654,
          "optimization_tag": "under_performance",
          "cost_coef": 1.8559163391,
          "days_coef": 620.3718411071,
          "intercept": -1832.0352255235
        },
        {
          "index": 11,
          "campaign": "RACCOON_CONV_DISCOVERY_SPICY_SP / 14754178785",
          "last_date": "2021-10-31T00:00:00.000Z",
          "projection_days": 60,
          "projected_max_date": "2021-12-30T00:00:00.000Z",
          "total_cost": 43313.31,
          "total_revenue": 52626.02,
          "total_profit": 9312.71,
          "cost_per_day": 1443.777,
          "suggested_cost_per_day": 1505.2304669924,
          "projected_cost": 90313.8280195468,
          "projected_revenue": 115088.0636346818,
          "projected_profit": 24774.235615135,
          "projected_roi": 0.274312762,
          "optimization_tag": "under_performance",
          "cost_coef": 1.2440886639,
          "days_coef": 75.4159752644,
          "intercept": -4057.7837750518
        },
        {
          "index": 3,
          "campaign": "LINHA LARANJA | LOJA SEARA | DISCOVERY ALL PRODUTO | REGIÕES | GENÉRICA / 12520702472",
          "last_date": "2021-09-09T00:00:00.000Z",
          "projection_days": 60,
          "projected_max_date": "2021-11-08T00:00:00.000Z",
          "total_cost": 83893.26,
          "total_revenue": 148093.86,
          "total_profit": 64200.6,
          "cost_per_day": 1271.11,
          "suggested_cost_per_day": 1694.7621327299,
          "projected_cost": 101685.7279637953,
          "projected_revenue": 201472.9789861859,
          "projected_profit": 99787.2510223905,
          "projected_roi": 0.9813299567,
          "optimization_tag": "under_performance",
          "cost_coef": 2.1431031067,
          "days_coef": -53.7562613435,
          "intercept": -9676.7315903935
        },
        {
          "index": 6,
          "campaign": "LINHA LARANJA | LOJA SEARA | DISCOVERY TOP PRODUTO | SP - RJ | GENÉRICA / 12520337880",
          "last_date": "2021-09-09T00:00:00.000Z",
          "projection_days": 60,
          "projected_max_date": "2021-11-08T00:00:00.000Z",
          "total_cost": 203591.17,
          "total_revenue": 838906.7800000001,
          "total_profit": 635315.6100000002,
          "cost_per_day": 1655.2127642276,
          "suggested_cost_per_day": 5847.5850026976,
          "projected_cost": 350855.100161853,
          "projected_revenue": 1418825.9720919582,
          "projected_profit": 1067970.8719301051,
          "projected_roi": 3.0439086433,
          "optimization_tag": "under_performance",
          "cost_coef": 4.0678314753,
          "days_coef": 372.0444343726,
          "intercept": -76477.5791059527
        },
        {
          "index": 19,
          "campaign": "RAC_GOOGLE_FRANGO-DE-PADARIA_PP_AWARENESS_SEARCH_INSTITUCIONAL_01022022 / 15032244618",
          "last_date": "2022-03-26T00:00:00.000Z",
          "projection_days": 60,
          "projected_max_date": "2022-05-25T00:00:00.000Z",
          "total_cost": 87096.24,
          "total_revenue": 228626.5099999999,
          "total_profit": 141530.2699999999,
          "cost_per_day": 1075.2622222222,
          "suggested_cost_per_day": 2009.2036609524,
          "projected_cost": 120552.2196571449,
          "projected_revenue": 222539.667389373,
          "projected_profit": 101987.4477322281,
          "projected_roi": 0.8460022389,
          "optimization_tag": "over_performance",
          "cost_coef": 0.149393477,
          "days_coef": 1396.5314976973,
          "intercept": 7619.0109533967
        },
        {
          "index": 20,
          "campaign": "RAC_GOOGLE_FRANGO-DE-PADARIA_PP_CONVERSAO_DISCOVERY_BIG-FONE_02032022 / 16500059260",
          "last_date": "2022-04-29T00:00:00.000Z",
          "projection_days": 60,
          "projected_max_date": "2022-06-28T00:00:00.000Z",
          "total_cost": 37393.04,
          "total_revenue": 42365.91,
          "total_profit": 4972.87,
          "cost_per_day": 1068.3725714286,
          "suggested_cost_per_day": 1101.1879498096,
          "projected_cost": 66071.2769885756,
          "projected_revenue": 73519.1396263305,
          "projected_profit": 7447.8626377549,
          "projected_roi": 0.1127246661,
          "optimization_tag": "under_performance",
          "cost_coef": -1.4862125654,
          "days_coef": 1751.4658743344,
          "intercept": 5325.8436393255
        },
        {
          "index": 7,
          "campaign": "LINHA LARANJA | LOJA SEARA | DSA | SEARA | NEW / 11447631324",
          "last_date": "2021-11-27T00:00:00.000Z",
          "projection_days": 60,
          "projected_max_date": "2022-01-26T00:00:00.000Z",
          "total_cost": 119198.51,
          "total_revenue": 151765.9999999999,
          "total_profit": 32567.49,
          "cost_per_day": 851.4179285714,
          "suggested_cost_per_day": 1066.3269262468,
          "projected_cost": 63979.6155748107,
          "projected_revenue": 109713.8514558371,
          "projected_profit": 45734.2358810263,
          "projected_roi": 0.7148251122,
          "optimization_tag": "over_performance",
          "cost_coef": 0.2005050843,
          "days_coef": 407.6659472754,
          "intercept": 15352.4237890642
        },
        {
          "index": 29,
          "campaign": "RAC_GOOGLE_PESCADOS_LW_CONVERSAO_SEARCH_PADRAO-GENERICO_11032022 / 16542562211",
          "last_date": "2022-04-28T00:00:00.000Z",
          "projection_days": 60,
          "projected_max_date": "2022-06-27T00:00:00.000Z",
          "total_cost": 30652.13,
          "total_revenue": 103533.03,
          "total_profit": 72880.9,
          "cost_per_day": 875.7751428571,
          "suggested_cost_per_day": 1356.7075441163,
          "projected_cost": 81402.4526469757,
          "projected_revenue": 291065.9863927883,
          "projected_profit": 209663.5337458126,
          "projected_roi": 2.5756414817,
          "optimization_tag": "under_performance",
          "cost_coef": 2.7205109177,
          "days_coef": 824.1881211354,
          "intercept": -8688.1462683168
        },
        {
          "index": 30,
          "campaign": "SEARA | LINHA GOURMET | DISCOVERY | BRASIL / 11235917924",
          "last_date": "2021-12-03T00:00:00.000Z",
          "projection_days": 60,
          "projected_max_date": "2022-02-01T00:00:00.000Z",
          "total_cost": 108730.39,
          "total_revenue": 180700.9,
          "total_profit": 71970.51,
          "cost_per_day": 2265.2164583333,
          "suggested_cost_per_day": 2740.1413041317,
          "projected_cost": 164408.4782478997,
          "projected_revenue": 133572.0769712312,
          "projected_profit": -30836.4012766685,
          "projected_roi": -0.1875596782,
          "optimization_tag": "over_performance",
          "cost_coef": 0.7880419684,
          "days_coef": 242.221340683,
          "intercept": -22148.6086438625
        }
      ]
    }

    useEffect(() => {

    })

   return (
      <Grid mt="80px">
         <GridItem>
            <ComplexTable columnsData={columnsDataComplex} tableData={returnResponse.data} />
         </GridItem>
      </Grid>
   )
}

export default Reports
